import React from "react";
import Helmet from "react-helmet";

function SEO(props: SEOProps) {
  const metaDescription =
    props.description ||
    "Rupiah.trade merupakan situs web konversi (convert) saldo PayPal USD ke saldo GoPay, Dana, LinkAja, Rekening Bank, Pulsa dan Item Game terpercaya di Indonesia.";

  return (
    <Helmet
      title={props.title}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },

        {
          name: `keywords`,
          content:
            "Rupiah.trade, convert paypal, tarik saldo paypal, top up, isi saldo, gopay, ovo, dana, linkaja, shopeepay, pulsa, pulsa paypal",
        },

        // Fb
        {
          name: `fb:pages`,
          content: "728989087147820",
        },
        {
          name: `fb:profile_id`,
          content: "100008143662357",
        },
        {
          name: `fb:app_id`,
          content: "504221637758330",
        },
        {
          property: `og:locale`,
          content: "id_ID",
        },
        {
          property: `og:title`,
          content: props.title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `og:image`,
          content: "https://rupiah.trade/images/image.png",
        },
        {
          name: `og:image:width`,
          content: "1200",
        },
        {
          name: `og:image:height`,
          content: "630",
        },

        // Twitter
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: "@ismail_xvii",
        },
        {
          name: `twitter:title`,
          content: props.title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: "https://rupiah.trade/images/image.png",
        },

        // Update
        {
          itemprop: `datePublished`,
          content: new Date("05 01 2022").toISOString(),
        },
        { itemprop: `dateModified`, content: new Date().toISOString() },
        {
          property: `article:published_time`,
          content: new Date("05 01 2022").toISOString(),
        },
        {
          property: `article:modified_time`,
          content: new Date().toISOString(),
        },
      ].concat(props.meta || [])}
    >
      <html lang={props.lang} />
      <script type="application/ld+json">
        {`{
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Rupiah.trade",
        "alternateName": "Rupiahtrade",
        "url": "https://rupiah.trade",
        "logo": "https://rupiah.trade/images/logo.png",
        "sameAs": [
          "https://facebook.com/rupiah.trade/",
          "https://instagram.com/rupiah.trade/",
          "https://rupiah.trade"
        ]
      }`}
      </script>
      {props.children}
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `id_ID`,
  meta: [],
  description: ``,
};

interface SEOProps {
  description?: string;
  lang?: string;
  children?: JSX.Element | any;
  meta?: MetaWithProperty[] | MetaWithName[];
  title: string;
}

interface MetaWithProperty {
  property: string;
  content: string;
}

interface MetaWithName {
  name: string;
  content: string;
}

export default SEO;
